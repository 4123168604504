import React, { FormEvent, useEffect, useState } from "react"
import { Accordion, Container, Row, Col, Image, Button, Form } from "react-bootstrap"
import { Contract } from "web3-eth-contract"
import ExamplePunk from "../../../images/ExamplePunk.png"
import PunkBanner from "../../../images/NOOBPUNKS_BANNER.png"
import { isMobile } from "../../../utils/device"
import MetaMaskConnectButton from "../../buttons/MetaMaskConnectButton";

const styles = require("./punks.module.scss")
const presaleTreeJson = require("./json/presale-tree.json");

type PunksProps = {
  accountAddress: string | null,
  contract: Contract,
  punksContract: Contract,
  getAndSetAccount: Function,
}

const PUNKS_SUPPLY_LIMIT: number = 10000;

/**
 * Punks page.
 */
const Punks: React.FC<PunksProps> = ({ accountAddress, contract, punksContract, getAndSetAccount }) => {
  // General state
  const [isLoading, setLoading] = useState(false);
  const [shouldShowError, setShouldShowError] = useState<boolean>(false);
  const [transactionUrl, setTransactionUrl] = useState("");

  // Punks contract state
  const [currentSupply, setCurrentSupply] = useState<number>(0);
  const [mintPrice, setMintPrice] = useState<number>(0);
  const [isMintingActive, setIsMintingActive] = useState<boolean>(false);

  // Form state
  const [numPunksToBuy, setNumPunksToBuy] = useState<string>("1");

  // Function to actually do the minting
  const mintPunks = () => {
    if (isMintingActive) {
      return punksContract.methods.mintPricePerNft().call().then((price: number) => {
        const numPunksBuying: number = parseInt(numPunksToBuy)
        return punksContract.methods.mintPunks(numPunksBuying).send(
          {
            from: accountAddress,
            value: price * numPunksBuying,
          })
          .on('transactionHash', (hash: string) => {
            setTransactionUrl("https://etherscan.io/tx/" + hash);
        })
      })
    } else {
      setLoading(false);
    }
  }

  // Punks contract state
  useEffect(() => {
    if (isLoading) {
      // TODO (Ben): Change
      mintPunks().then(() => {
        setLoading(false)
      }).catch(e => {
        setLoading(false)
        setShouldShowError(true);
      });
    }
    if (punksContract && accountAddress) {
      punksContract.methods.totalSupply().call().then((supply: number) => {
        setCurrentSupply(supply)
      })

      punksContract.methods.mintPricePerNft().call().then((price: number) => {
        setMintPrice(price / 1000000000000000000)
      })

      punksContract.methods.isMintingActive().call().then((isMintingActive: boolean) => {
        setIsMintingActive(isMintingActive);
      })
    }
  }, [punksContract, accountAddress, isLoading]);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
  }

  const hasSaleStarted = isMintingActive;
  const remainingPunks = hasSaleStarted ? String(PUNKS_SUPPLY_LIMIT - currentSupply) : "10000";
  const isSoldOut = ((PUNKS_SUPPLY_LIMIT === currentSupply) && hasSaleStarted);

  const { ethereum } = (typeof window !== 'undefined') ? window : { ethereum: null };
  const isMetaMaskInstalled = ethereum && ethereum.isMetaMask;
  const isMobileAndMetamaskNotInstalled = isMobile() && !isMetaMaskInstalled
  const mintLimit = 20
  const currentPrice = mintPrice

  return (
    <>
      <Container id="punks-intro" className="d-flex align-items-center justify-content-center">
        <Row>
          <Col className="col-12 col-lg-6 d-flex flex-column align-items-center justify-content-center">
            <Row className="d-flex align-items-center justify-content-center">
              <Col className="d-flex align-items-center justify-content-center">
                <Image src={PunkBanner} />
              </Col>
            </Row>

                <Row className="d-flex align-items-center justify-content-center" style={{width: "100%"}}>
                  <Col className="d-flex align-items-center justify-content-center">
                    <h1 className="noobs-sold-out-mint-number">SOLD OUT</h1>
                  </Col>
                </Row>
                <Row className="d-flex align-items-center justify-content-center" style={{width: "100%"}}>
                  <Col>
                    <a href="https://opensea.io/collection/noobpunks-nft" target="_blank">
                      <Button
                        variant="primary"
                        className="noob-mint-button"
                        style={{width: "100%"}}
                      >
                        View on OpenSea
                      </Button>
                    </a>
                  </Col>
                </Row>
          </Col>
          <Col className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
            <Image src={ExamplePunk} className="punks-intro-image" />
          </Col>
        </Row>
      </Container>

      <Container id="faq" className="noob-section" fluid>
        <Row>
          <Col>
            <h1 className={styles.textHeader}>FAQ</h1>
            <Accordion flush>
              <Accordion.Item eventKey="3">
                <Accordion.Header><span className={styles.textQuestion}>First time buyer?</span></Accordion.Header>
                <Accordion.Body>
                  <p>The first step would be setting up your very own wallet using the <a href="https://metamask.io/" target="_blank" className="primary-color-link">metamask plugin</a> on your browser.</p>
                  <p>Once that is done, you're going to need some ETH. You can either buy this directly through metamask, or via an exchange (we recommend  <a href="https://www.gemini.com/share/vkz9wzh8" target="_blank" className="primary-color-link">Gemini</a> but <a href="https://www.coinbase.com/" target="_blank" className="primary-color-link">Coinbase</a> is another option as well).</p>
                  <p>With your ETH in hand, you are going to want to withdraw to your metamask address (if you purchased on an exchange). Then connect your wallet to our site using the option in the navigation bar.</p>
                  <p>Voilà! Just like that you are now ready to buy some NOOBPUNKS  - and may we say we are honored to have been your first foray into this wonderful world.</p>
                  <p>If you have any trouble please message the support channel in our Discord server and we will be more than happy to lend a hand.</p>
                  <span>Please note that our team will never contact you to request any personal or other identifying information. Be especially careful with your wallet and NEVER share your seed phrase with anyone.</span>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header><span className={styles.textQuestion}>Can I purchase a NOOBPUNK on a mobile device?</span></Accordion.Header>
                <Accordion.Body>
                  <span>Only through the <a href="https://metamask.io/" target="_blank" className="primary-color-link">MetaMask</a> mobile browser. Otherwise, we only support purchasing on desktop devices via <a href="https://metamask.io/" target="_blank" className="primary-color-link">MetaMask</a>.</span>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header><span className={styles.textQuestion}>Where can I learn more about the contract?</span></Accordion.Header>
                <Accordion.Body>
                  <p>You can view any of the transaction, code, etc. on <a href="https://etherscan.io/address/0xa5a1e6972ace6f4ae388fbafcb7ec12013b64f53" target="_blank" className="primary-color-link">Etherscan</a>.</p>
                  <span>You can learn more about how we leveraged ERC721A to make a gas efficient contract <a href="https://www.nftculture.com/guides/gas-optimizations-in-noobpunks/" target="_blank" className="primary-color-link">here</a>.</span>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <br/>
    </>
  )
}

export default Punks
